import React, {Fragment, Suspense, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Route, Routes} from "react-router-dom";

import {SignInPage} from "@pages/SignInPage/SignInPage";
import {HomePage} from "@pages/HomePage/HomePage";

import {selectUserIsExist} from "@entities/Authentication/model/selectors/authenticationSelectors";
import SidebarLayout from "@shared/layouts/SidebarLayout/SidebarLayout";
import {ProtectedRoute} from "./layout/ProtectedRoute";
import {UncollectedOrderPage} from "@pages/UncollectedOrderPage/UncollectedOrderPage";
import {OrderNavbarLayout} from "@widgets/OrderNavbarLayout/OrderNavbarLayout";
import {NewOrderTab} from "@widgets/OrderTabs/ui/NewOrderTab/NewOrderTab";
import {InProgressOrderTab} from "@widgets/OrderTabs/ui/InProgressOrderTab/InProgressOrderTab";
import {ReadyOrderTab} from "@widgets/OrderTabs/ui/ReadyOrderTab/ReadyOrderTab";
import {socketConnectActionRequest} from "@entities/Socket/model/slice/socketSlice";
import {
    selectSocketData,
    selectSocketDataId,
    selectSocketDataStatus
} from "@entities/Socket/model/selectors/socketSelectors";
import {addOrderList, removeItemFromOrderList} from "@entities/Order/model/slice/orderSlice";
import {NotificationPage} from "@pages/NotificationPage/NotificationPage";
import {WorkDaysPage} from "@pages/WorkDaysPage/WorkDaysPage";

import "./store/i18n"
import {addToNotificationList} from "@entities/NotificationList/model/slice/notificationListSlice";
import useSound from "use-sound";
// @ts-ignore
import mySound from "@shared/sounds/new-order.mp3";

const App = () => {
    const [play] = useSound(mySound);
    const dispatch = useDispatch();

    const user = useSelector(selectUserIsExist);

    const data = useSelector(selectSocketData);
    const dataId = useSelector(selectSocketDataId);
    const orderStatus = useSelector(selectSocketDataStatus);

    console.log(process.env.REACT_APP_SOCKET_API)
    console.log(process.env.REACT_APP_DJANGO_API)

    useEffect(() => {
        if(user) {
            dispatch(socketConnectActionRequest({ message: "messages" }))
        }
    }, [dispatch, user]);


    useEffect(() => {

        if(orderStatus === "new") {
            dispatch(addToNotificationList({
                id: data?.id,
                isRead: false,
                phone: data?.user?.phone,
                code: data?.secret_code,
                price: data?.price,
                productCount: data?.products?.reduce((prev: number, curr: any) => prev + curr?.quantity, 0),
                status: data?.status
            }))
            dispatch(addOrderList(data))
            play();
        }
        if(orderStatus === "rejected") {
            dispatch(removeItemFromOrderList(data))
        }
    }, [dispatch, orderStatus, data, dataId]);

    return (
        <div>
            <Suspense fallback="">
                <Routes>
                    {user ? (
                        <Fragment>
                            <Route path="/dashboard" element={
                                <ProtectedRoute>
                                    <SidebarLayout>
                                        <HomePage />
                                    </SidebarLayout>
                                </ProtectedRoute>
                            } />
                            <Route path="/dashboard/work-days" element={
                                <ProtectedRoute>
                                    <SidebarLayout>
                                        <WorkDaysPage />
                                    </SidebarLayout>
                                </ProtectedRoute>
                            } />
                            <Route path="/dashboard/notifications" element={
                                <ProtectedRoute>
                                    <SidebarLayout>
                                        <NotificationPage />
                                    </SidebarLayout>
                                </ProtectedRoute>
                            } />
                            <Route path="/dashboard/order/" element={
                                <ProtectedRoute>
                                    <OrderNavbarLayout />
                                </ProtectedRoute>
                            }>
                                <Route path="new" element={<NewOrderTab />} />
                                <Route path="in-progress" element={<InProgressOrderTab />} />
                                <Route path="ready" element={<ReadyOrderTab />} />
                            </Route>
                            <Route path="/dashboard/uncollected-order" element={
                                <ProtectedRoute>
                                    <SidebarLayout>
                                        <UncollectedOrderPage />
                                    </SidebarLayout>
                                </ProtectedRoute>
                            } />
                            {/*<Route path="/dashboard/menu" element={*/}
                            {/*    <ProtectedRoute>*/}
                            {/*        <SidebarLayout>*/}
                            {/*            <MenuPage />*/}
                            {/*        </SidebarLayout>*/}
                            {/*    </ProtectedRoute>*/}
                            {/*} />*/}
                            <Route
                                path="*"
                                element={
                                    <Navigate
                                        to="/dashboard"
                                        replace
                                    />
                                }
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Route path="/sign-in" element={<SignInPage />} />

                            <Route
                                path="*"
                                element={
                                    <Navigate
                                        to="/sign-in"
                                        replace
                                    />
                                }
                            />
                        </Fragment>
                    )}
              </Routes>
          </Suspense>
        </div>
    );
}


export default App;
