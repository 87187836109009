import { io, Socket } from 'socket.io-client';

export let socket: Socket | null = null;

// http://34.46.18.165:5000

export const initiateSocketConnection = (authToken: string) => {
    if (socket) return;

    // @ts-ignore
    socket = io(process.env.REACT_APP_SOCKET_API, {
        reconnection: true,
        transports: ['websocket'],
        reconnectionAttempts: 5,
        reconnectionDelay: 5000,
        forceNew: true
    });

    socket.on('connect', () => {
        socket?.emit("bind:channel", { user_id: authToken });
    });



    // socket.on('notification', (data) => {
    //     console.log('Received notification:', data);
    // });
    //
    // socket.on('connect_error', (error) => {
    //     NotificationManager.error("Ошибка подключения. Пожалуйста, повторите позже.");
    // });
    //
    // socket.on('connect_timeout', (timeout) => {
    //     console.log(`Connection timeout: ${timeout}`);
    // });
    //
    // socket.on('reconnect', () => {
    //     NotificationManager.success("Успешное повторное подключение при попытке");
    // });

    // socket.on('reconnect_attempt', () => {
    //     NotificationManager.warning("Attempting to reconnect...");
    // });

    // socket.on('reconnect_error', () => {
    //     NotificationManager.error("Попытка повторного подключения");
    // });

    // socket.on('reconnect_failed', () => {
    //     NotificationManager.error(`Не удалось повторно подключиться. Повторю попытку через ${Number(socket?.io?.opts.reconnectionDelay) / 1000} секунд`);
    // });
};

export const disconnectSocket = () => {
    if (socket) {
        socket.disconnect();
        socket = null;
    }
};

export const subscribeToChat = (channel: string, callback: (error: any, msg: string | null, ) => void) => {
    if (!socket) return;

    socket.on(channel, (msg: string) => {
        callback(null, msg);
    });
};

export const sendMessage = ({ channel, message }: { channel: string, message: string }) => {
    if (socket) {
        return new Promise((resolve, reject) => {
            socket?.emit(channel, message, (response: {error: any, data: any}) => {
                if (response.error) {
                    reject(response.error);
                } else {
                    resolve(response.data);
                }
            });
        });
    }
};
